var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-section"
  }, [_c('div', {
    staticClass: "mt-0"
  }, [_vm.$options.components['advertisement-loading'] ? [_vm.isLoading ? _c('advertisement-loading') : _vm._e()] : _vm._e(), _c('div', {
    staticClass: "container mt-3"
  }, [_vm.productData ? _c('b-row', {
    staticClass: "product-main my-3"
  }, [_c('b-col', {
    staticClass: "product-border product-images",
    attrs: {
      "md": "6"
    }
  }, [_vm.productData['Discount'] || _vm.productData['Discount(%)'] ? _c('div', {
    staticClass: "show-variant"
  }, [_vm.productData['Discount'] ? _c('span', {
    staticClass: "variant-span"
  }, [_vm._v(" ₹" + _vm._s(_vm.productData["Discount"]) + " off ")]) : _vm._e(), _vm.productData['Discount(%)'] ? _c('span', {
    staticClass: "variant-span"
  }, [_vm._v(" " + _vm._s(_vm.productData["Discount(%)"]) + "% off ")]) : _vm._e()]) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, _vm._l(_vm.imagesArr, function (product, i) {
    var _ref;

    return _c('div', {
      key: i,
      staticClass: "productdetail-imgshort",
      on: {
        "mouseover": function mouseover($event) {
          return _vm.setActiveBox(i);
        }
      }
    }, [_c('b-img', {
      staticClass: "product-thumb-image my-3",
      class: [(_ref = {}, _ref[i === _vm.activeBox ? 'box-hovered' : 'box-common'] = true, _ref)],
      attrs: {
        "src": product,
        "center": "",
        "height": "100",
        "width": "100",
        "id": "product-img-1"
      }
    })], 1);
  }), 0), _c('b-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('div', {
    staticClass: "align-items-center product-main-image-column"
  }, [_c('b-img', {
    staticClass: "product-main-image my-3",
    attrs: {
      "id": "product-img-1",
      "src": _vm.imagesArr[_vm.activeBox],
      "alt": "",
      "height": "400",
      "width": "360"
    }
  })], 1)])], 1)], 1), _c('b-col', {
    staticClass: "bg-color product-info pr-0 pl-3",
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-row justify-content-end product-icons"
  }, [_c('div', {
    staticClass: "product-icon-circle pointer heart mb-2 mr-4"
  }, [_vm.wishlistKey ? _c('b-icon', {
    attrs: {
      "size": "xl",
      "icon": "heart-fill"
    },
    on: {
      "click": function click($event) {
        return _vm.removeItem(_vm.productData);
      }
    }
  }) : _c('b-icon', {
    attrs: {
      "size": "xl",
      "icon": "heart"
    },
    on: {
      "click": function click($event) {
        return _vm.addToWishlist(_vm.productData);
      }
    }
  })], 1)]), _vm.productData.Name || _vm.productData.Quantity ? _c('h1', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.productData.Name) + " " + _vm._s(_vm.productData.Quantity) + " ")]) : _vm._e(), _vm.productData.Brand ? _c('p', {
    staticClass: "text-center product-location mb-0"
  }, [_vm._v(" " + _vm._s(_vm.productData.Brand) + " ")]) : _vm._e(), _c('div', {
    staticClass: "text-center d-flex mt-3 flex-column justify-content-sm-between product-price"
  }, [_vm.productData['Discount(%)'] ? _c('div', {
    staticClass: "d-flex price-del-width"
  }, [_c('del', {
    staticClass: "mdi mdi-currency-inr text-icon price mb-0 mr-3"
  }, [_vm._v("MRP" + _vm._s(_vm.productData.Price))]), _c('h1', {
    staticClass: "text-center mdi mdi-currency-inr text-icon price mb-0 margin-left-8"
  }, [_vm._v(" " + _vm._s(_vm.productData["Deal Price"]) + " ")]), _c('p', [_vm._v("Price Inclusive Of All Taxes")])]) : _vm._e(), _vm.productData['Discount'] ? _c('div', {
    staticClass: "d-flex price-del-width"
  }, [_c('del', {
    staticClass: "mdi mdi-currency-inr text-icon price mb-0 mr-3"
  }, [_vm._v(_vm._s(_vm.productData.Price))]), _c('h1', {
    staticClass: "mdi mdi-currency-inr text-icon price mb-0 margin-left-8"
  }, [_vm._v(" " + _vm._s(_vm.productData["Deal Price"]) + " ")])]) : _vm._e(), !_vm.productData['Discount'] && !_vm.productData['Discount(%)'] ? _c('h1', {
    staticClass: "text-center mdi mdi-currency-inr text-icon price mb-0 product-price-mobile"
  }, [_vm._v(" " + _vm._s(_vm.productData.Price) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "d-flex flex-row justify-content-center"
  }, [_vm.productData.providerName ? _c('div', {
    staticClass: "d-flex"
  }, [_c('dt', {
    staticClass: "mr-1"
  }, [_vm._v("Provider :")]), _c('router-link', {
    attrs: {
      "to": {
        path: "/shopDetails/".concat(_vm.productData.provider, "/").concat(_vm.productData.providerName)
      }
    }
  }, [_vm._v(_vm._s(_vm.productData.providerName))])], 1) : _vm._e()]), _c('div', {
    staticClass: "product-rate d-flex flex-column align-items-center justify-content-sm-between"
  }, [_vm.productFeedback ? _c('div', {
    staticClass: "d-flex flex-row mr-5"
  }, [_c('div', {
    staticClass: "d-flex flex-row product-stars"
  }, [_c('b-form-rating', {
    attrs: {
      "variant": "warning",
      "color": "#fd7e14",
      "value": _vm.ratingStarCount,
      "readonly": ""
    }
  }), _c('h2', {
    staticClass: "mt-2"
  }, [_vm._v(" (" + _vm._s(_vm.productFeedback.ratingCount || "0") + ") ")])], 1), _c('h2', {
    staticClass: "ml-2 mt-2 write-review",
    on: {
      "click": function click($event) {
        return _vm.$bvModal.show('product-review');
      }
    }
  }, [_vm._v(" Write Review ")])]) : _vm._e()]), _c('h2', {
    staticClass: "text-center"
  }, [_vm._v("Different colors")]), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "6",
      "offset": "3"
    }
  }, [_c('div', {
    staticClass: "text-center fashionDev-slider-div"
  }, [_c('VueSlickCarousel', _vm._b({}, 'VueSlickCarousel', _vm.settings, false), _vm._l(_vm.colors, function (i, index) {
    return _c('div', {
      key: index,
      staticClass: "containers-image",
      attrs: {
        "id": "carousel-home-middle2"
      }
    }, [_c('button', {
      staticClass: "button-colors",
      style: {
        background: "".concat(i.bg)
      },
      on: {
        "click": function click($event) {
          return _vm.onClickColor(i.bg);
        }
      }
    })]);
  }), 0)], 1)])], 1), _c('h2', {
    staticClass: "text-center"
  }, [_vm._v("Select Size")]), _c('div', {
    staticClass: "size-button-div"
  }, _vm._l(_vm.sizes, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('button', {
      staticClass: "size-button",
      attrs: {
        "type": "button",
        "id": item.text,
        "value": item.value
      },
      on: {
        "click": function click($event) {
          return _vm.onClickSelectSize(item.value);
        }
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")])]);
  }), 0), _c('b-modal', {
    attrs: {
      "id": "product-review",
      "size": "md",
      "centered": "",
      "title": "Review This Product",
      "hide-footer": ""
    }
  }, [_c('v-container', [_c('v-row', [_c('b-input-group', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-start"
  }, [_c('div', [_c('b-img', {
    staticClass: "product-main-image",
    attrs: {
      "id": "product-img-1",
      "src": _vm.imagesArr[_vm.activeBox],
      "alt": "",
      "height": "80",
      "width": "80"
    }
  })], 1), _c('div', {
    staticClass: "mt-3 ml-3"
  }, [_c('h4', {
    staticStyle: {
      "color": "#fd7e14"
    }
  }, [_vm._v(_vm._s(_vm.productData.Name))])])])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v("Rate this Product:")]), _c('b-form-rating', {
    staticClass: "pl-0 pr-0",
    attrs: {
      "variant": "warning",
      "color": "#fd7e14",
      "inline": "",
      "size": "lg"
    },
    model: {
      value: _vm.ratingStar,
      callback: function callback($$v) {
        _vm.ratingStar = $$v;
      },
      expression: "ratingStar"
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h6', [_vm._v("Additional Comments")]), _c('div', {
    staticClass: "mb-3"
  }, [_c('b-form-textarea', {
    staticClass: "mt-3",
    staticStyle: {
      "background": "#f8f8f8"
    },
    attrs: {
      "placeholder": "Description",
      "rows": "5"
    },
    model: {
      value: _vm.feedbackComment,
      callback: function callback($$v) {
        _vm.feedbackComment = $$v;
      },
      expression: "feedbackComment"
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center mt-2"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary mr-3 pr-5 pl-5",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$bvModal.hide('product-review');
      }
    }
  }, [_c('span', {
    staticClass: "button-text"
  }, [_vm._v("Clear")])]), _c('button', {
    staticClass: "btn btn-primary ml-3",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "button-text pr-5 pl-5",
    on: {
      "click": _vm.submitProductReview
    }
  }, [_vm._v("Save")])])])])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex flex-row justify-content-center"
  }, [_vm.productData.Availability && _vm.productData.Availability === 'OUT OF STOCK' ? _c('div', [_c('p', {
    staticClass: "text-danger font-weight-bold"
  }, [_vm._v("OUT OF STOCK")])]) : _c('div', {
    staticClass: "add-button add-button-mobile mb-2",
    attrs: {
      "id": "add-cart"
    }
  }, [_vm.$options.components['checkout-increment-decrement'] ? [_c('checkout-increment-decrement', {
    attrs: {
      "callCheckout": false,
      "cartItem": _vm.productData,
      "themeColors": this.theme
    }
  })] : _vm._e()], 2)])], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-row justify-content-center"
  }, [_c('h5', [_vm._v("Handpicked Styles | Assured Quality")])])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "bg-color",
    attrs: {
      "md": "6"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "p-2"
  }, [_vm._v("Rate This Product:")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.productFeedback ? _c('div', {
    staticClass: "d-flex flex-row mr-5"
  }, [_c('div', {
    staticClass: "d-flex flex-row product-stars"
  }, [_c('b-form-rating', {
    attrs: {
      "variant": "warning",
      "color": "#fd7e14",
      "value": _vm.ratingStarCount,
      "readonly": ""
    }
  }), _c('h5', {
    staticClass: "mt-2"
  }, [_vm._v(" (" + _vm._s(_vm.productFeedback.ratingCount || "0") + ") ")])], 1)]) : _vm._e()])], 1), _vm.productFeedback ? _c('div', _vm._l(_vm.productFeedback.ratings, function (rating) {
    return _c('div', {
      key: rating.userId,
      staticClass: "rating-reviews"
    }, [_c('div', {
      staticClass: "mb-4"
    }, [_c('div', {
      staticClass: "d-flex flex-row align-items-center"
    }, [_c('div', {
      staticClass: "d-flex flex-row star-count pl-1 pr-1",
      style: Object.keys(_vm.theme).length !== 0 ? {
        background: "".concat(_vm.theme.primaryColor.color)
      } : ''
    }, [_c('div', [_c('p', {
      staticClass: "star-color-fashion-market mb-0"
    }, [_vm._v(" " + _vm._s(rating.stars) + " ")])]), _c('div', {
      staticClass: "mr-2"
    }, [_c('b-icon', {
      staticStyle: {
        "color": "white"
      },
      attrs: {
        "icon": "star-fill"
      }
    })], 1)]), _c('div', {
      staticClass: "ml-4"
    }, [_c('h6', [_vm._v(_vm._s(rating.givenBy.name.first))])])]), _c('div', {
      staticClass: "review-comment mt-2"
    }, [_c('p', [_vm._v(_vm._s(rating.feedbackComment))])]), _c('p', {
      staticClass: "userName"
    }, [_vm._v(" " + _vm._s(rating.givenBy.name.first + rating.givenBy.name.last) + " ")]), _c('div', [_c('img', {
      staticClass: "mr-2",
      attrs: {
        "src": "/img/tick-mark.png"
      }
    }), _c('span', [_vm._v("Certified Buyer")])])])]);
  }), 0) : _c('div', [_c('p', [_vm._v("No reviews yet!")])])], 1), _c('b-col', {
    staticClass: "bg-color",
    attrs: {
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v("Products Details")]), _c('ul', {
    staticClass: "p-3"
  }, [_c('li', {
    staticClass: "description_summary-li"
  }, [_vm._v(" " + _vm._s(_vm.productData.description_summary) + " ")])])])], 1)], 1), _c('div', {
    staticClass: "bg-colors mb-5"
  }, [_vm.relatedItems && _vm.relatedItems.length ? _c('sections-slider', {
    staticClass: "suggested-slider",
    attrs: {
      "list": _vm.relatedItems,
      "subTitle": "Suggested For You",
      "theme": _vm.theme,
      "hasButton": true,
      "onlyTitle": false,
      "showTitle": true,
      "itemSubTitle": true,
      "hasBackground": false,
      "withSlider": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var listItem = _ref2.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('items-fashion-item', {
          staticClass: "suggester-div",
          attrs: {
            "itemNoBg": true,
            "product": listItem,
            "incrementDecrementKey": true
          }
        })], 1)];
      }
    }], null, false, 424376726)
  }) : _vm._e()], 1)], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }